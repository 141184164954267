import type { NextPage } from 'next';
import React, { useEffect } from 'react';
import Head from 'next/head';
import { Scene } from '../src/components/organismn/Scene/Scene';
import ContractList from '../src/components/ContractList/ContractList';
import WhatsNewModal from '../src/components/WhatsNewModal/WhatsNewModal';
import { AuthGuard } from '../src/components/Auth/AuthGuard';
import { SurveySummaryTools } from '../src/components/Dev/SurveySummeryTools';
import { ContractListNavigation } from '../src/components/ContractList/ContractListNavigation';
import { SyncModal } from '../src/components/SyncModal/SyncModal';

const Home: NextPage = () => {
    useEffect(() => {
        localStorage.removeItem('contractTab');
    }, []);

    return (
        <AuthGuard access={'sv'}>
            <Head>
                <title>Aufträge</title>
            </Head>
            <Scene
                fullscreen
                hideSidebar
                pageTitle="Auftragsliste"
                testId="sv-contract-list"
                stickySubHeader={<ContractListNavigation />}
            >
                <ContractList />

                <SurveySummaryTools />
            </Scene>
            {/* Modals */}
            <WhatsNewModal />
            <SyncModal />
        </AuthGuard>
    );
};
export default Home;
