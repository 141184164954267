import React, { useCallback, useEffect } from 'react';
import { useFilesystem } from '../../hooks/useFilesystem';
import { useEnvironment } from '../../hooks/useEnvironment';
import { Button } from '../buttons/Button';

interface ISurveySummaryTools {}

export const SurveySummaryTools: React.FC<ISurveySummaryTools> = () => {
    const { deleteFilesInDir } = useFilesystem();

    const { isProduction } = useEnvironment();

    const clearFilesystem = useCallback(() => {
        if (isProduction) return null;

        void deleteFilesInDir(['photos', 'photo-vaults', 'forms'], []);
    }, [deleteFilesInDir, isProduction]);

    useEffect(() => {
        if (!isProduction && window !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.clearFilesystem = clearFilesystem;
        }
    }, [clearFilesystem, isProduction]);

    return (
        <>
            {!isProduction && (
                <section className="hidden">
                    <Button testId="clearContractButton" onClick={clearFilesystem} />
                </section>
            )}
        </>
    );
};
