import React, { useEffect, useMemo, useState } from 'react';
import { Note } from '../atoms/Note';
import { ProgressBar } from '../BLForms/atoms/ProgressBar';
import { Modal } from '../atoms/Modal/Modal';
import { useSyncContext } from '../../context/syncContext';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../Auth/userAtom';

interface ISyncModal {}

export const SyncModal: React.FC<ISyncModal> = () => {
    const [progress, setProgress] = useState(0);

    const { id, roles } = useRecoilValue(userAtom);

    const hasAdminRole = useMemo(() => {
        if (!id) {
            return null;
        }

        return roles?.some((role) => role.access.includes('admin'));
    }, [id, roles]);

    const [{ firstSync, position, count }] = useSyncContext();

    useEffect(() => {
        if (firstSync) {
            const temp = Math.ceil((position / count) * 100);
            setProgress(temp < 0 ? 0 : temp);
        }
    }, [count, firstSync, position]);

    return (
        <>
            <Modal
                // show={true}
                show={firstSync && !hasAdminRole}
                closeable={false}
                title="Synchronisiere Daten"
                afterClose={() => {}}
            >
                <div className="flex flex-col gap-4 p-1" data-test-id="app-sync-modal">
                    <h1>Der aktuelle Server Stand wird nun mit der App synchronisiert</h1>
                    <Note severity="info">Dieser Prozess kann einige Minuten dauern</Note>

                    <div className="flex w-full">
                        <ProgressBar percentage={progress} />
                    </div>
                </div>
            </Modal>
            {/* For Testing */}
            {!firstSync && count > 0 && <div data-test-id="app-sync-modal" className="hidden" />}
            {!firstSync && count > 0 && <div data-test-id="first-sync-done" className="hidden" />}
        </>
    );
};
